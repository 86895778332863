"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CashSystem = exports.OfferTemplateWithdrawalType = exports.PaymentMethod = exports.OfferTemplateSalesType = exports.OfferTemplatePeriod = exports.Currency = exports.ArticleFamily = exports.ArticleBaking = void 0;
var ArticleBaking;
(function (ArticleBaking) {
    ArticleBaking["MEDIUM"] = "MEDIUM";
    ArticleBaking["MEDIUMRARE"] = "MEDIUMRARE";
    ArticleBaking["RARE"] = "RARE";
    ArticleBaking["RAW"] = "RAW";
    ArticleBaking["WELLDONE"] = "WELLDONE";
})(ArticleBaking = exports.ArticleBaking || (exports.ArticleBaking = {}));
var ArticleFamily;
(function (ArticleFamily) {
    ArticleFamily["BEVERAGE"] = "BEVERAGE";
    ArticleFamily["BREAD"] = "BREAD";
    ArticleFamily["DAIRY"] = "DAIRY";
    ArticleFamily["DESSERT"] = "DESSERT";
    ArticleFamily["DISH"] = "DISH";
    ArticleFamily["FRUIT"] = "FRUIT";
    ArticleFamily["MISCELLANEOUS"] = "MISCELLANEOUS";
    ArticleFamily["PASTRY"] = "PASTRY";
    ArticleFamily["SALAD"] = "SALAD";
    ArticleFamily["SANDWICH"] = "SANDWICH";
    ArticleFamily["SIDE_DISH"] = "SIDE_DISH";
    ArticleFamily["SNACKING"] = "SNACKING";
    ArticleFamily["SOUP"] = "SOUP";
    ArticleFamily["STARTER"] = "STARTER";
    ArticleFamily["BREAK"] = "BREAK";
    ArticleFamily["FORMULA"] = "FORMULA";
})(ArticleFamily = exports.ArticleFamily || (exports.ArticleFamily = {}));
var Currency;
(function (Currency) {
    Currency["EUR"] = "EUR";
})(Currency = exports.Currency || (exports.Currency = {}));
var OfferTemplatePeriod;
(function (OfferTemplatePeriod) {
    OfferTemplatePeriod["DAILY"] = "DAILY";
    OfferTemplatePeriod["WEEKLY_MON"] = "WEEKLY_MON";
})(OfferTemplatePeriod = exports.OfferTemplatePeriod || (exports.OfferTemplatePeriod = {}));
var OfferTemplateSalesType;
(function (OfferTemplateSalesType) {
    OfferTemplateSalesType["BUNDLE"] = "BUNDLE";
    OfferTemplateSalesType["FREE_SALE"] = "FREE_SALE";
})(OfferTemplateSalesType = exports.OfferTemplateSalesType || (exports.OfferTemplateSalesType = {}));
var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["Badge"] = "BADGE";
    PaymentMethod["CreditCard"] = "CREDIT_CARD";
    PaymentMethod["Edenred"] = "EDENRED";
    PaymentMethod["OnSite"] = "ON_SITE";
})(PaymentMethod = exports.PaymentMethod || (exports.PaymentMethod = {}));
var OfferTemplateWithdrawalType;
(function (OfferTemplateWithdrawalType) {
    OfferTemplateWithdrawalType["CONNECTED_LOCKERS"] = "CONNECTED_LOCKERS";
    OfferTemplateWithdrawalType["POS_AT_SITE"] = "POS_AT_SITE";
    OfferTemplateWithdrawalType["POS_TAKE_AWAY"] = "POS_TAKE_AWAY";
    OfferTemplateWithdrawalType["TABLE_SERVICE"] = "TABLE_SERVICE";
    OfferTemplateWithdrawalType["POS_CLICK_SERVE"] = "POS_CLICK_SERVE";
    OfferTemplateWithdrawalType["INSTANT_CLICK_COLLECT"] = "INSTANT_CLICK_COLLECT";
    OfferTemplateWithdrawalType["CLICK_AND_PICK_UP"] = "CLICK_AND_PICK_UP";
})(OfferTemplateWithdrawalType = exports.OfferTemplateWithdrawalType || (exports.OfferTemplateWithdrawalType = {}));
var CashSystem;
(function (CashSystem) {
    CashSystem["DUMMY"] = "999";
    CashSystem["CSI"] = "1";
    CashSystem["JES"] = "2";
    CashSystem["OSCAR"] = "3";
    CashSystem["OTHERS"] = "1007";
})(CashSystem = exports.CashSystem || (exports.CashSystem = {}));
