import React, { useEffect, useMemo, useRef, useState } from "react";
import { RefillInputs } from "@atomic";
import {
  OrderViewModel,
  PaymentsThunks,
  Refills,
  RefillViewModel,
  TransactionsActions,
} from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { submitToUrl } from "@utils/submitToUrl";
import { GlobalState } from "@redux";
import { I18n } from "react-redux-i18n";

const MAX_AMOUNT_SCOLAPASS = 5000;
const MAX_AMOUNT_REFILL = 150;

import { PaymentInput } from "@atomic/organisms/PaymentInput";
import { StyleSheet, View } from "react-native";
import { Colors, Spacing } from "@constants";
import { useDevices } from "@hooks";
import { TestIDs } from "@utils";
import { Title16, Title22 } from "@stylesheets";
import moment from "moment";
import { CashSystem } from "@foodi/core/lib/domain/valueObjects/globalTypes";
import { ManagementMode } from "@foodi/core";

export const RefillsTemplate: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const accessToken = useSelector(
    (state: GlobalState) => state.auth.authToken?.accessToken
  );
  const balanceInfo = useSelector(
    (state: GlobalState) => state.account.userBalance?.balance
  );
  const userInfo = useSelector(
    (state: GlobalState) => ({
      idGuest: state.auth.userInfo?.idGuest,
      state: state.auth.userInfo?.state,
      validFrom: state.auth.userInfo?.validFrom,
      validTo: state.auth.userInfo?.validTo,
      idCashSystem: state.auth.userInfo?.idCashSystem,
      managementMode: state.auth.userInfo?.managementMode
    })
  );
  const { isScolaPassCashSystem } =
    useSelector((state: GlobalState) => state?.auth?.userInfo) ?? {};

  const prevSelectedInput = useRef(-1);
  const refillVM = new RefillViewModel();
  const balance = parseFloat(balanceInfo?.amount as string);
  const defaultPayment = balance < 0 ? Math.abs(balance) : 0;

  // useState
  const [refills, setRefills] = useState<Refills[]>([]);
  const [isOtherRefill, setOtherRefill] = useState<boolean>(false);
  const otherValue = React.useRef(0);
  const paymentValue = React.useRef(defaultPayment);

  const [isMobile] = useDevices();
  const styles = useMemo(() => _styles(isMobile), [isMobile]);
  const Title: React.FC<any>  = isMobile ? Title16 : Title22;
  const orderVM = new OrderViewModel(dispatch, userInfo.idGuest || '');

  // useEffects
  useEffect(() => {
    if (!isOtherRefill) {
      setRefills(refillVM.getInitState());
    } else {
      prevSelectedInput.current = -1;
    }
  }, [isOtherRefill]);

  const onSelectRefillInput = (inputIndex: number) => {
    if (prevSelectedInput.current === inputIndex) return;
    const refills = refillVM.getRefillInputs(inputIndex);
    const otherRefillIndex = refills?.length === 2 ? 1 : 3;
    prevSelectedInput.current = inputIndex;
    setRefills(refills);
    setOtherRefill(inputIndex === otherRefillIndex);
  };

  /* istanbul ignore next */
  const handleValidate = async () => {
    dispatch(TransactionsActions.setMercanetStatus(true));

    const amount = (function () {
      if (isScolaPassCashSystem) {
        return `${paymentValue.current}`;
      }
      return isOtherRefill
        ? `${otherValue.current}`
        : refills?.[prevSelectedInput.current]?.amount;
    })();

    if (amount && accessToken) {
      //@ts-ignore
      const { uri, method, body } = await dispatch(
        PaymentsThunks.getPaymentRequest(
          amount,
          accessToken,
          // @ts-ignore
          window.config.MERCANET_CALLBACK_URL
        )
      );
      submitToUrl(uri, method, body);
    }
  };

  const handleOtherValue = (value: number) => {
    otherValue.current = value;
  };

  const handlePayment = (value: number) => {
    paymentValue.current = value;
  };

  const title = isScolaPassCashSystem
    ? I18n.t("refill.scolapass.payment")
    : I18n.t("refill.refill");

  const maxAmount = isScolaPassCashSystem
    ? MAX_AMOUNT_SCOLAPASS
    : MAX_AMOUNT_REFILL;

  const errorDesc = I18n.t("refill.refillLimitError", {
    amount: String(maxAmount),
  });

  const badgeIsExpiredAndShouldShowErrorMessage = (): boolean => {
    return !!(orderVM.isBadgeExpired(userInfo.state, userInfo.validFrom ? moment(userInfo.validFrom) : null, userInfo.validTo ? moment(userInfo.validTo) : null)
      && userInfo.managementMode === ManagementMode.PRE
      && userInfo.idCashSystem
      && [CashSystem.CSI, CashSystem.JES, CashSystem.OSCAR].includes(userInfo.idCashSystem as CashSystem));
  }

  if (badgeIsExpiredAndShouldShowErrorMessage()) {

    return (<View style={styles.container}>
      <Title
        testID={TestIDs.home.balance.texts.refillText}
        style={styles.title}
      >
        {I18n.t("refill.expiredBadge")}
      </Title>
    </View>);
  }

  if (isScolaPassCashSystem) {
    return (
      <PaymentInput
        onValidate={handleValidate}
        handlePayment={handlePayment}
        balance={balance}
        defaultPayment={defaultPayment}
        maxAmount={MAX_AMOUNT_SCOLAPASS}
        title={title}
        errorDescription={errorDesc}
      />
    );
  }

  return (
    <RefillInputs
      onValidate={handleValidate}
      handleOtherValue={handleOtherValue}
      onSelectRefillInput={onSelectRefillInput}
      refills={refills}
      isOtherRefill={isOtherRefill}
      setOtherRefill={setOtherRefill}
      isDisabled={refillVM.noInputIsSelected(refills)}
      title={title}
      errorDescription={errorDesc}
    />
  );
});

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      width: "100%",
      backgroundColor: Colors.background1,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 8,
      paddingHorizontal: isMobile ? 0 : Spacing.XL,
      paddingVertical: isMobile ? Spacing.M : Spacing.XXL,
    },
    title: {
      color: Colors.redError,
      fontFamily: "manrope-bold",
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  });
