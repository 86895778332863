import { NavigationProp, Route } from "@react-navigation/native";
import { View, StyleSheet, Image } from "react-native";
import React, { useEffect, useMemo, useRef } from "react";
import {
  BookingThunks,
  CalendarViewModel,
  OfferViewModel,
  PointOfSaleActions,
} from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { BookingCart, LoaderAnimationComponent } from "@atomic";
import { uniqBy } from "lodash";
import { GlobalState } from "@redux";
import { OfferProductsList } from "@atomic/organisms/OfferProductsList";
import moment from "moment";
import { Title16 } from "@stylesheets";
import { Images, Information } from "@assets";
import { LOCAL, DEV, TestIDs } from "@utils";
import { I18n } from "react-redux-i18n";
import {
  IPosMenu,
  IPosMenuElement,
} from "@foodi/core";
import { useDevices } from "@hooks";
import { Holding } from "@foodi/core/lib/domain/entities/Holding";
import _ from 'lodash';

interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
  isModify: any;
}

export type IImportationTypes = "OSCAR" | "WINAPRO" | "OTHER";

export const IMPORTATION_TYPES: { [key in IImportationTypes]: key } = {
  OSCAR: "OSCAR",
  WINAPRO: "WINAPRO",
  OTHER: "OTHER",
};

export const menuFamiliesRank: { [key in IImportationTypes]: string[] } = {
  [IMPORTATION_TYPES.OSCAR]: [
    "breakfast",
    "menuStarters",
    "salads",
    "menuHotMeals",
    "menuAccompaniment",
    "cheeses",
    "dairies",
    "menuDesserts",
    "dessertsBar",
    "toTaste",
  ],
  [IMPORTATION_TYPES.WINAPRO]: [
    "menuStarters",
    "menuHotMeals",
    "menuAccompaniment",
    "menuCheese",
    "desserts",
    "menuCompliments",
    "toTaste",
  ],
  [IMPORTATION_TYPES.OTHER]: [],
};

export type IDishGroup =
  | "STARTERS"
  | "MEALS"
  | "DESSERTS"
  | "ACCOMP"
  | "COMPLEMENT"
  | "FROMAGE"
  | "GOUTER"
  | "PETIT DEJEUNER"
  | "SALAD BAR AU KILO"
  | "DESSERTS BAR AU KILO"
  | "DAIRIES";

export const DISH_GROUP_ID: {
  [key in IImportationTypes]: { [key in IDishGroup]: string };
} = function() {
    const base64Encoder = (obj: { [key in IDishGroup]: string }) => {
      if (LOCAL || DEV) {
        return obj;
      }

      return _.reduce(obj, (acc, value, index) => ({
        ...acc,
        [index]: btoa(value)
      }), {} as { [key in IDishGroup]: string });
    }

  const oscarDishGroupMap = {
    DESSERTS: 'MenuElementDishGroup:7',
    MEALS: 'MenuElementDishGroup:2',
    STARTERS: 'MenuElementDishGroup:4',
    ACCOMP: 'MenuElementDishGroup:3',
    FROMAGE: 'MenuElementDishGroup:9',
    DAIRIES: 'MenuElementDishGroup:8',
    COMPLEMENT: "",
    GOUTER: "MenuElementDishGroup:13",
    "PETIT DEJEUNER": 'MenuElementDishGroup:15',
    "SALAD BAR AU KILO": 'MenuElementDishGroup:5',
    "DESSERTS BAR AU KILO": 'MenuElementDishGroup:6',
  };

  const winaproDishGroupMap = {
    DESSERTS: 'MenuElementDishGroup:7cc0db9f5d1193d34a42c31030221f79',
    MEALS: 'MenuElementDishGroup:93f559324d132c32dc9423a27c73d839',
    STARTERS: 'MenuElementDishGroup:2af1573609f2de0fe3b4fbc4a042db9a',
    ACCOMP: 'MenuElementDishGroup:73899d8bf2ea45ee715d03f9b5d94e34',
    COMPLEMENT: 'MenuElementDishGroup:fdd815953df9dd54d578a8f8f843e690',
    FROMAGE: 'MenuElementDishGroup:4ec3863e5692686b0aa11d61ec1b30d4',
    GOUTER: 'MenuElementDishGroup:a5a3962729f799cc1339f0de7554369d',
    DAIRIES: "",
    "PETIT DEJEUNER": '',
    "SALAD BAR AU KILO": '',
    "DESSERTS BAR AU KILO": '',
  };

  const defaultDishGroupMap = {
    DESSERTS: "",
    MEALS: "",
    STARTERS: "",
    ACCOMP: "",
    COMPLEMENT: "",
    FROMAGE: "",
    GOUTER: "",
    DAIRIES: "",
    "PETIT DEJEUNER": '',
    "SALAD BAR AU KILO": '',
    "DESSERTS BAR AU KILO": '',
  };

  return ({
    [IMPORTATION_TYPES.OSCAR]: base64Encoder(oscarDishGroupMap),
    [IMPORTATION_TYPES.WINAPRO]: base64Encoder(winaproDishGroupMap),
    [IMPORTATION_TYPES.OTHER]: defaultDishGroupMap,
  })
}();

const NO_WRAP_CART_TOP = -75;
const WRAP_CART_TOP = 30;

const DISH_ID_ORDER: {
  [key in IImportationTypes]: { [key: string]: string[] };
} = function () {
  const base64Encoder = (array: string[]) =>
    (LOCAL || DEV) ? array : array.map((item) => btoa(item));

  return ({
    [IMPORTATION_TYPES.WINAPRO]: {
      [DISH_GROUP_ID.WINAPRO.MEALS]: [],
      [DISH_GROUP_ID.WINAPRO.STARTERS]: [],
      [DISH_GROUP_ID.WINAPRO.DESSERTS]: [],
    },
    [IMPORTATION_TYPES.OSCAR]: {
      [DISH_GROUP_ID.OSCAR.MEALS]: base64Encoder(['MenuElementDish:41', 'MenuElementDish:44', 'MenuElementDish:51', 'MenuElementDish:52', 'MenuElementDish:6', 'MenuElementDish:37']),
      [DISH_GROUP_ID.OSCAR.STARTERS]: base64Encoder(['MenuElementDish:1', 'MenuElementDish:3', 'MenuElementDish:8', 'MenuElementDish:69']),
      [DISH_GROUP_ID.OSCAR.DESSERTS]: base64Encoder(['MenuElementDish:28', 'MenuElementDish:68', 'MenuElementDish:212', 'MenuElementDish:47', 'MenuElementDish:70']),
    },
    [IMPORTATION_TYPES.OTHER]: {
      [DISH_GROUP_ID.WINAPRO.MEALS]: [],
      [DISH_GROUP_ID.WINAPRO.STARTERS]: [],
      [DISH_GROUP_ID.WINAPRO.DESSERTS]: [],
    },
  })
}();

export const BookingTemplate: React.FC<IProps> = React.memo(
  ({ navigation, route, isModify }) => {
    const dispatch = useDispatch();
    //@ts-ignore
    const id = route?.params?.id;
    const cartRef = useRef();
    const [cartTopX, setCartTopX] = React.useState<number>(NO_WRAP_CART_TOP);

    const [menu, setMenu] = React.useState<any>();
    const [holding, setHolding] = React.useState<Holding>();
    const [menus, setMenus] = React.useState<any>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const selectedDay = useSelector(
      (state: GlobalState) => state.booking?.selectedDay ?? 0
    );

    const isBookingSelected = useSelector(
      (state: GlobalState) => state.booking?.isBookingSelected
    );

    const isBookingButtonAvailable = useSelector(
      (state: GlobalState) => state?.pointOfSale?.posBookingButtonStatus
    );

    const userLanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );

    const hasNewOrder = useSelector(
      (state: GlobalState) => state?.pointOfSale?.hasNewOrder
    );

    const pointOfSale = useSelector(
      (state: GlobalState) => state.pointOfSale?.selectedPos?.pointOfSale
    );

    const [CalendarVM] = React.useState<CalendarViewModel>(
      new CalendarViewModel()
    );

    const [offerVM] = React.useState(new OfferViewModel());

    useEffect(() => {
      try {
        getBookingOffers();
      } catch (e) {}
      if (isMobile && isBookingButtonAvailable) {
        dispatch(PointOfSaleActions.setMiniBookingCartStatus(true));
      } else {
        dispatch(PointOfSaleActions.setMiniBookingCartStatus(false));
      }
    }, [hasNewOrder]);

    const getPosition = () => {
      //@ts-ignore
      if (
        //@ts-ignore
        cartRef?.current?.offsetTop > NO_WRAP_CART_TOP &&
        //@ts-ignore
        cartRef?.current?.offsetTop !== WRAP_CART_TOP
      ) {
        setCartTopX(WRAP_CART_TOP);
      } else {
        setCartTopX(NO_WRAP_CART_TOP);
      }
    };

    useEffect(() => {
      handlePosResponse();
    }, [id, userLanguage]);

    const handlePosResponse = () => {
      const menusValue = pointOfSale?.menus;
      getMenu(menusValue);
      setMenus(menusValue);
      setHolding(pointOfSale?.zone.holding);
    };

    useEffect(() => {
      getPosition();
      try {
        window.addEventListener("resize", getPosition);
      } catch (e) {}
    }, []);

    useEffect(() => {
      getMenu(menus);
    }, [selectedDay, holding]);

    const getMenu = (menus: IPosMenu[] | undefined) => {
      const { dayNumber, fullDate } = CalendarVM.getDateInfo(selectedDay, userLanguage);

      const menuValue = menus?.find(
        (m: IPosMenu) => moment(m?.day)?.format("YYYY-MM-DD") === fullDate
      )?.elements;

      const menuFiltered = getFilteredMenu(menuValue as any);

      const offerItems = offerVM.getGroupedProducts(menuFiltered as any);
      const offerItemsSorted = getSortedOfferItems(offerItems as any);
      setMenu(offerItemsSorted);
    };

    const getFilteredMenu = (menu: IPosMenuElement[] | undefined) => {
      if (!menu) return null;
      if (!holding) return null;
      let idElements: number[] = [];

      const cleanedMenus = uniqBy(
        menu.filter(({ id, label, products }) => {
          return !menu.some((menu) => {
            if (
              menu.id != id &&
              menu.label === label &&
              products?.length < menu?.products.length
            ) {
              return true;
            }
          });
        }),
        "label"
      );
      return cleanedMenus.filter((element: IPosMenuElement) => {
        const idElement = element.idElement;

        if (
          !Object.values(
            DISH_GROUP_ID[holding.importationType as IImportationTypes]
          ).includes(element.dish.dishGroup.id) ||
          idElements.includes(idElement)
        ) {
          return false;
        }

        idElements.push(idElement);
        return true;
      });
    };

    const getSortedOfferItems = (offerItems: any | null) => {
      if (!offerItems) return null;
      if (!holding) return null;
      const offerItemsList = Object.entries(offerItems);
      offerItemsList.forEach(([key, value]: [string, any]) => {
        const sortBy =
          DISH_ID_ORDER[holding.importationType as IImportationTypes][
            key.trim()
          ];
        if (!!sortBy) {
          value.sort(
            (a: { dish: { id: string } }, b: { dish: { id: string } }) => {
              if (!sortBy.includes(a.dish.id) && sortBy.includes(b.dish.id)) {
                return 1;
              } else if (
                sortBy.includes(a.dish.id) &&
                !sortBy.includes(b.dish.id)
              ) {
                return -1;
              } else {
                return sortBy.indexOf(a.dish.id) - sortBy.indexOf(b.dish.id);
              }
            }
          );
        }
      });
      return Object.fromEntries(offerItemsList);
    };

    const getBookingOffers = async () => {
      setIsLoading(true);
      await dispatch(
        BookingThunks.getBookingTemplateOffer({
          idPos: id,
        })
        //@ts-ignore
      ).finally(() => {
        if (hasNewOrder) dispatch(PointOfSaleActions.setNewOrder(false));
        setIsLoading(false);
      });
    };

    if (isLoading) {
      return (
        <View style={styles.loadingStyle}>
          <LoaderAnimationComponent />
        </View>
      );
    }

    return (
      <View style={styles.productsDiv}>
        {menu && holding ? (
          <View style={styles.productsDiv}>
            <OfferProductsList
              holding={holding}
              offerItems={menu}
              isBookingSelected={isBookingSelected}
              selectedDay={selectedDay}
              menuType={holding.importationType as IImportationTypes}
            />
          </View>
        ) : (
          <View style={styles.productsList}>
            <View style={styles.productsDivUnavailable}>
              <Information />
              <Title16 style={styles.unavailableText}>
                {I18n.t("restaurantDetail.unavailableBooking")}
              </Title16>
            </View>
            <Image style={styles.image} source={{ uri: Images.noBooking }} />
          </View>
        )}
        {isBookingButtonAvailable && !isMobile && (
          <View
            testID={TestIDs.restaurantDetail.views.bookingCartContainer}
            style={[styles.cardDiv, { marginTop: cartTopX }]}
            //@ts-ignore
            ref={cartRef}
          >
            <BookingCart
              navigation={navigation}
              idPos={id}
              isModify={isModify}
            />
          </View>
        )}
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    unavailableText: {
      marginHorizontal: 6,
      width: "100%",
    },
    productsDiv: {
      flexDirection: "row",
      marginRight: 50,
    },
    productsDivUnavailable: {
      flexDirection: "row",
      marginLeft: isMobile ? 18 : 0,
      width: isMobile ? "95vw" : "100%",
    },
    cardDiv: {
      marginTop: -75,
    },
    image: {
      height: 240,
      width: 831,
      resizeMode: "cover",
      marginTop: 38,
      marginLeft: isMobile ? -18 : 0,
    },
    productsList: {
      marginRight: 50,
    },
    loadingStyle: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      minHeight: "300px",
    },
  });
