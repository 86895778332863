import {
  CheckTablesAvailabilityCommand,
  TablesAvailability,
} from "@foodi/core";
import { CalendarViewModel } from "@modules";

import { createReducer, GlobalState, LoaderActions, ThunkResult } from "@redux";

import { Action } from "@redux/action";
import { displayToastError } from "@utils";
import _ from "lodash";

export interface TableState {
  tablesAvailability?: TablesAvailability;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_TABLES_AVAILABILITY: "Table/SET_TABLES_AVAILABILITY",
};

const ActionCreators = {
  setTablesAvailability: (tablesAvailability: TablesAvailability): Action<TablesAvailability> => ({
    type: ActionTypes.SET_TABLES_AVAILABILITY,
    payload: tablesAvailability,
  }),
};

const ThunkActionCreators = {
  checkTablesAvailability: (
    params: CheckTablesAvailabilityCommand
  ): ThunkResult<Promise<void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      if(params.availableTables){
        dispatch(ActionCreators.setTablesAvailability(params.availableTables))
        return
      }
      const { checkTablesAvailability } = getDependencies();
      dispatch(LoaderActions.setLoading(true));
      const tablesAvailability = await checkTablesAvailability.execute(params);
      dispatch(ActionCreators.setTablesAvailability(tablesAvailability));
      dispatch(LoaderActions.setLoading(false));
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
};

/*************  Reducer  ****************/

const initialState: TableState = {
  tablesAvailability: undefined,
};

const Reduction = {
  setTablesAvailability: (
    state: TableState,
    { payload: tablesAvailability }: Action<TablesAvailability>
  ): TableState => ({
    ...state,
    tablesAvailability,
  }),
};
const reducer = createReducer(initialState, {
  [ActionTypes.SET_TABLES_AVAILABILITY]: Reduction.setTablesAvailability,
});

export default reducer;

export {
  reducer as TableReducer,
  ActionTypes as TableActionTypes,
  ActionCreators as TableActions,
  ThunkActionCreators as TableThunks,
};
