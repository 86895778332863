import React, { useState, useMemo } from "react";
import { IAmount, PreferredZoneType } from "@foodi/core";
import { Text, View, StyleSheet, TouchableOpacity, Dimensions } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { GlobalState } from "@redux";
import { useDevices } from "@hooks";
import { Spacing, Colors } from "@constants";
import { PreferredZonesThunks } from "@modules";
import { Heart } from "@assets";
import { Button } from "@atomic";
import { arraySortByName } from "@utils";

export interface PreferredZoneProps {
	idHoldingView?: string;
	preferredZones: PreferredZoneType[];
	maximumPreferredZones: number;
  zones: PreferredZoneType[];
  onClose: (props?: any) => void;
  modalHeight?: number;
  shouldUpdateZones?: boolean
  onPreferredZoneSelect?: (props?: any) => void;
}

const MINIMUM_PREFERRED_ZONES = 1;

const zoneItem = (item: any, index: number, styles: any, isSelected: boolean, onSelect: any) => {
	return (
		<TouchableOpacity
			key={`PreferedZones_${index}_${item}`}
			style={[styles.zoneCard, isSelected && styles.zoneCardSelected]}
			onPress={() => onSelect(item)}
		>
        { isSelected ? (
          <View style={styles.iconContainer}>
            <Heart />
          </View>
        ): (
          <Text style={styles.plusIcon}>+</Text>
        )}
			<Text style={isSelected ? [styles.zoneCardText, styles.zoneCardTextSelected] : styles.zoneCardText} numberOfLines={1} ellipsizeMode="tail">
				{item.name}
			</Text>
		</TouchableOpacity>
	);
};

export const PreferredZone = React.memo(({
  idHoldingView, 
  preferredZones, 
  zones, 
  modalHeight, 
  maximumPreferredZones, 
  shouldUpdateZones = false,
  onPreferredZoneSelect = () => {},
  onClose = () => {},
}: PreferredZoneProps) => {
  const dispatch = useDispatch();

  const [isMobile] = useDevices();
  const brandTheme = useSelector(
    (state: GlobalState) => state.brandTheme.brandTheme)
  
  const styles = useMemo(() => _styles(isMobile, modalHeight, brandTheme), [isMobile, modalHeight, brandTheme]);

  const [selectedZones, setSelectedZones] = useState<PreferredZoneType[]>(preferredZones);

  const [disableSubmit, setDisableSubmit] = useState<boolean>(
    preferredZones.length < MINIMUM_PREFERRED_ZONES
  );

  const onSelect = (zoneToSelect: PreferredZoneType) => {
    
    const selectedIndex = selectedZones.findIndex(({ id }) => id === zoneToSelect.id);
    let tempZones = [...selectedZones];

    if (selectedIndex !== -1) {
      tempZones.splice(selectedIndex, 1);
    } else {
      tempZones.push(zoneToSelect);
    }

    setSelectedZones(tempZones);
    setDisableSubmit(
      tempZones.length < MINIMUM_PREFERRED_ZONES || 
      tempZones.length > maximumPreferredZones
    );
  };

  const onSubmit = () => {
    try {
      // this part is used inside the homepage filters

      const sortedZones = selectedZones.sort(arraySortByName);

      if (shouldUpdateZones) {
        const updatedZones: PreferredZoneType[] = [];
        const zonesDeselected: PreferredZoneType[] = [];

        // check which zones were deselected as preferred zones
        preferredZones.forEach(eachZone => {
          const foundZone = selectedZones.findIndex(eachSelectedZone => eachSelectedZone.id == eachZone.id);

          if(foundZone === -1){
            updatedZones.push(eachZone);
          }

        })

        // check which zones are new preferred zones
        selectedZones.forEach(eachZone => {
          const foundZone = preferredZones.findIndex(eachSelectedZone => eachSelectedZone.id == eachZone.id);
          
          if(foundZone === -1){
            updatedZones.push(eachZone);
          }

        })
        onPreferredZoneSelect({updatedZones, updatedPreferredZones: selectedZones});

        dispatch(PreferredZonesThunks.updatePreferredZones({
          input: {
            idHoldingView,
            preferredZones: sortedZones,
          }
        }));

      }

      onClose(sortedZones);
    } catch (e) {
      console.error(`ERROR - updatePreferredZones - ${e}`);
    }
  };

  const infoMessage = maximumPreferredZones === MINIMUM_PREFERRED_ZONES ? 
    I18n.t('preferredZones.minimumZones', { minZones: MINIMUM_PREFERRED_ZONES }) :
    I18n.t('preferredZones.maxZones', { minZones: MINIMUM_PREFERRED_ZONES, maxZones: maximumPreferredZones });

	return (
		<View style={styles.mainContainer}>
			<View style={styles.header}>
				<Text style={styles.title}>{I18n.t("preferredZones.modals.title")}</Text>
        <Text style={styles.subTitle}>{I18n.t("preferredZones.modals.subTitle")}</Text>
        <Text style={styles.infoMessage}>
          {infoMessage}
        </Text>
			</View>
      <View style={styles.cardsContainer}>
        {zones?.map((eachZone, index) => {
          const isZoneSelected =
            selectedZones.findIndex(({ name }) => name === eachZone.name) !== -1;

          return zoneItem(eachZone, index, styles, isZoneSelected, onSelect);
        })}
      </View>
      <Button
        onPress={onSubmit}
        label={I18n.t("preferredZones.modals.validateBtn")}
        styleButton={styles.submitBtn}
        disabled={disableSubmit}
      />
		</View>
	);
});

const _styles = (isMobile: boolean, modalHeight?: number, brandTheme?: any) => 
  StyleSheet.create({
    mainContainer: {
      padding: Spacing.L,
      alignItems: "center",
      height: modalHeight ? modalHeight : 500,
      width: "100%",
    },
    header: {
      textAlign: "center",
      marginBottom: 5,
    },
    description: {
      textAlign: "center",
      fontFamily: "Manrope-Bold",
      fontSize: 13,
      color: Colors.foodiBlack,
      marginTop: 12,
    },
    infoMessage: {
      fontFamily: "Manrope",
      fontSize: 13,
      color: Colors.foodiBlack,
      marginTop: 5,
    },
    touchableView: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    title: {
      fontFamily: "Manrope-Bold",
      fontSize: 22,
      lineHeight: 30,
      color: Colors.foodiBlack,
      textAlign: "center",
    },
    subTitle: {
      textAlign: "center",
      fontFamily: "Manrope-Bold",
      fontSize: 13,
      color: Colors.foodiBlack,
      marginTop: 12,
    },
    cardsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: "100%",
    },
    firstZone: {
      backgroundColor: Colors.foodiDefault,
    },
    nextZone: {
      textAlign: "center",
      fontFamily: "Manrope-Bold",
      fontSize: 13,
      borderColor: Colors.foodiDefault,
      borderRadius: 8,
      width: 100,
      padding: 8,
      borderWidth: 1,
    },
    zoneCardText: {
      color: Colors.foodiBlack,
      fontFamily: "manrope",
      fontSize: 16,
      maxWidth: 200,
    },
    zoneCardTextSelected: {
      color: brandTheme.buttonActionColors?.textColor || Colors.foodiBlack,
    },
    closeXButtonStyle: {
      top: -15,
      right: -5,
    },
    modalStyle: {
      width: 414,
      maxHeight: 650,
    },
    submitBtn: {
      position: 'absolute',
      bottom: 0,
      marginBottom: isMobile ? 0 : Spacing.L,
      width: isMobile ? Dimensions.get('window').width : '200px',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      height: 60,
      borderRadius: isMobile ? 0 : 8,
    },
    zoneCard: {
      maxWidth: 200,
      height: 40,
      borderRadius: 8,
      borderWidth: 0.5,
      paddingVertical: 5,
      paddingHorizontal: 15,
      marginTop: 10,
      marginRight: 10,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row'
    },
    zoneCardSelected: {
      backgroundColor: brandTheme.buttonActionColors?.backgroundColor || Colors.foodiDefault,
      borderWidth: 0,
    },
    plusIcon: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: 30,
      marginRight: 5,
      lineHeight: 30
    },
    iconContainer: {
      marginRight: 5,
      color: brandTheme.buttonActionColors?.textColor || Colors.foodiBlack,
    }
});

