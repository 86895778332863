import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { GlobalState } from "@redux";
import { useSelector } from "react-redux";
import { Header } from "@atomic";
import { AccountNavigation, POSNavigation } from "@modules";
import { BalanceNavigation } from "@modules/balance";
import { ProfileNavigation } from "@modules/profile";
import { ArticleDetail, HomeScreen, IDPCallback } from "../screens";
import { HomeScreens } from "./HomeScreens";

export type HomeStackParamList = {
  [HomeScreens.HOME_SCREEN]: undefined;
  [HomeScreens.BALANCE_SCREEN]: undefined;
  [HomeScreens.POINT_OF_SALE_STACK]: undefined;
  [HomeScreens.PROFILE_STACK]: undefined;
  [HomeScreens.ARTICLE_SCREEN]: undefined;
  [HomeScreens.ACCOUNT_STACK]: undefined;
  [HomeScreens.IDPCallback]: undefined;
};

const Stack = createStackNavigator<HomeStackParamList>();

export const HomeNavigation = () => {
  const isUserLoggedIn = useSelector(
    (state: GlobalState) => state.auth?.isLogin
  );

  return (
    <Stack.Navigator
      screenOptions={({ navigation, route }) => ({
        header: () => <Header navigation={navigation} route={route} />,
      })}
    >
      <Stack.Screen
        name={HomeScreens.HOME_SCREEN}
        key="homeScreen"
        component={HomeScreen}
      />

      <Stack.Screen
        name={HomeScreens.ACCOUNT_STACK}
        key="Account"
        component={AccountNavigation}
      />

      {isUserLoggedIn && (
        <>
          <Stack.Screen
            name={HomeScreens.BALANCE_SCREEN}
            key="balanceScreen"
            component={BalanceNavigation}
          />
          <Stack.Screen
            name={HomeScreens.POINT_OF_SALE_STACK}
            key="pointOfSaleStack"
            component={POSNavigation}
          />
          <Stack.Screen
            name={HomeScreens.PROFILE_STACK}
            key="profileStack"
            component={ProfileNavigation}
          />
          <Stack.Screen
            name={HomeScreens.ARTICLE_SCREEN}
            key="articleScreen"
            component={ArticleDetail}
          />
        </>
      )}
    </Stack.Navigator>
  );
};
