import React, { useEffect } from "react";
import { HomeNavigation, MaintenanceNavigation } from "@modules";
import { GlobalState, NavigationActions, SplashScreenState } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import { useToggleWebsocket } from "@hooks";

export const RootNavigator: React.FC = () => {
  const {
    maintenance: { inMaintenance, isTester },
  } = useToggleWebsocket();

  const dispatch = useDispatch();

  const { isInitialLinkHandled, splashScreenState } = useSelector(
    (state: GlobalState) => state.navigation
  );

  useEffect(() => {
    // hook to be run once when the app start to check if a deeplink opened
    if (
      !isInitialLinkHandled &&
      splashScreenState === SplashScreenState.PLAYED
    ) {
      dispatch(NavigationActions.setIsInitialLinkHandled(true));
    }
  }, [splashScreenState, isInitialLinkHandled, dispatch]);

  return inMaintenance && !isTester ? (
    <MaintenanceNavigation />
  ) : (
    <HomeNavigation />
  );
};
