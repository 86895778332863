import { ToggleThunks } from "@modules/toggle";
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import useWebSocket from "react-use-websocket";
import { ToggleWebsocketActions } from "@constants";
import messageHandler from "./websocket-actions/messageHandler";
import { GlobalState } from "@redux";
import jwtDecode from "jwt-decode";

type Context = {
  maintenance: {
    inMaintenance: boolean;
    isTester: boolean;
  };
};

const ToggleWebsocketContext = createContext<Context>({
  maintenance: {
    inMaintenance: false,
    isTester: false,
  },
});

export const useToggleWebsocket = () => useContext(ToggleWebsocketContext);

type ToggleWebsocketProviderProps = {
  children: ReactNode;
};

type Message = {
  data: string;
};

export type MessageData = {
  type: string;
  data: [];
};

export const ToggleWebsocketProvider = ({
  children,
}: ToggleWebsocketProviderProps) => {
  const [inMaintenance, setInMaintenance] = useState<boolean>(false);
  const [isTester, setIsTester] = useState(false);

  const accessToken = useSelector(
    (state: GlobalState) => state.auth.authToken?.accessToken
  );

  useEffect(() => {
    if (accessToken) {
      const clientInfo = jwtDecode(accessToken) as Record<string, string>;
      setIsTester(Boolean(clientInfo["https://foodi.com/isTester"]));
    }
  }, [accessToken]);

  const environment = window.config.FOODI_ENV || "LOCAL";
  const applicationId = "FOODI-MONOREPO";

  const dispatch = useDispatch();

  const setters = {
    setInMaintenance,
  };

  const onMessageHandler = (message: Message, lastJsonMessage: any) => {
    const data: MessageData = JSON.parse(message.data);
    if (
      !data ||
      _.isEqual(data, lastJsonMessage) ||
      !messageHandler[data.type]
    ) {
      return;
    }

    return messageHandler[data.type](
      data,
      lastJsonMessage,
      setters,
      environment
    );
  };

  const getUrl = useCallback(() => {
    return new Promise<string>((resolve, reject) => {
      const getToggle: any = () => {
        return dispatch(ToggleThunks.getToken({ type: "read" }));
      };

      getToggle().then((data: any) => {
        const token = data?.access_token;
        if (token) {
          resolve(`wss://${window.config.WEBSOCKET_URL}/?token=${token}`);
        }
        reject("");
      });
    });
  }, []);

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(getUrl, {
    onOpen: () => {
      sendJsonMessage({
        action: ToggleWebsocketActions.RETRIEVE_FEATURES,
        application_id: applicationId,
        environment,
      });
    },
    onMessage: (message) => {
      onMessageHandler(message, lastJsonMessage);
    },
    onClose: () => {
      console.info("[Websocket] Closed connection successfully");
    },
    share: true,
    heartbeat: {
      message: JSON.stringify({ action: "ping" }),
      returnMessage: JSON.stringify({ type: "PONG" }),
      interval: 1000 * 60,
      timeout: 1000 * 60 * 5,
    },
    onError: (event) => {
      console.error(
        "[Websocket] Could not connect or maintain connection: ",
        event
      );
    },
    retryOnError: true,
    reconnectAttempts: 5,
    reconnectInterval: 1000 * 60,
  });

  return (
    <ToggleWebsocketContext.Provider
      value={{
        maintenance: {
          inMaintenance,
          isTester,
        },
      }}
    >
      {children}
    </ToggleWebsocketContext.Provider>
  );
};
