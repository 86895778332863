import { TokenRefreshLink } from "apollo-link-token-refresh";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { BrandThemeActions, persistedStore, store } from "@redux";
import {
  AccountActions,
  AuthActions,
  AuthThunks,
  BookingActions,
  HomeScreens,
  OffersActions,
  PaymentsActions,
  PreferredZonesThunks,
  FiltersActions
} from "@modules";
import { getExpirationTokenInfoFromExp } from "@utils";
import { getApolloClient } from "..";
import { navigationService } from "@navigation";

const EXPIRATION_TIME_KEY = "exp";

/* istanbul ignore next */
const updateAccessToken = async (token: any) => {
  const isIDPConnexion = store.getState().auth.isIDPConnexion;
  const accessToken = isIDPConnexion ? token.accessToken : token;
  const decodeToken = jwtDecode(accessToken) as Record<string, number>;
  const expirationTokenInfo = getExpirationTokenInfoFromExp(
    decodeToken[EXPIRATION_TIME_KEY]
  );

  if (isIDPConnexion) {
    await store.dispatch(
      AuthActions.setAuthToken({
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
        emissionDate: expirationTokenInfo.emissionDate,
        expirationDate: expirationTokenInfo.expirationDate,
        expiresIn: expirationTokenInfo.expireIn,
      })
    );
    return token;
  } else {
    await store.dispatch(
      AuthActions.setAuthToken({
        emissionDate: expirationTokenInfo.emissionDate,
        expirationDate: expirationTokenInfo.expirationDate,
        expiresIn: expirationTokenInfo.expireIn,
        accessToken,
      })
    );
  }
};

/* istanbul ignore next */
const handleError = async (error: any): Promise<void> => {
  await getApolloClient().cache.reset();

  store.dispatch(AuthActions.logOutUser());
  store.dispatch(AccountActions.setUserBalance(null));
  store.dispatch(BookingActions.initBookingOrders());
  store.dispatch(OffersActions.initOrderItems());
  store.dispatch(BrandThemeActions.resetBrandTheme());
  store.dispatch(PaymentsActions.resetPayment());
  store.dispatch(PreferredZonesThunks.resetPreferredZonesStore());
  store.dispatch(FiltersActions.resetState());

  persistedStore.purge();

  navigationService.navigate(HomeScreens.HOME_SCREEN);
};

/* istanbul ignore next */
export const refreshLink = new TokenRefreshLink({
  accessTokenField: "accessToken",
  isTokenValidOrUndefined: (): boolean => {
    const expirationDate = store.getState().auth.authToken?.expirationDate;
    if (!expirationDate) {
      // unauthenticated queries must not attempt to refresh token
      return true;
    }
    return moment(expirationDate).isAfter(moment().toISOString());
  },
  fetchAccessToken: async (): Promise<any> => {
    const isIDPConnexion = store.getState().auth.isIDPConnexion;
    if (isIDPConnexion) {
      const refreshToken = store.getState().auth.authToken?.refreshToken;
      const { data }: any = await store.dispatch(
        AuthThunks.refreshIDPToken(`${refreshToken}`)
      );
      return data.refreshToken;
    } else {
      return store.dispatch(AuthThunks.refreshToken());
    }
  },
  handleError,
  handleFetch: (newToken) => newToken,
  handleResponse: (operation, accessTokenField) => updateAccessToken,
});
