import { AppRegistry } from "react-native";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "styled-components";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { LoaderActions, persistedStore, store } from "@redux";
import React, { useEffect, useState } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { getApolloClient as Client } from "components/src/apollo";
import { navigationRef } from "components/src/navigation";
import { DeeplinkConfigs, RootNavigator } from "./navigation";
import GlobalStyles, { theme } from "components/src/configuration/style/global.style";
import { AuthActions, AuthThunks } from "@modules/auth";
import "components/src/utils/icons";
import { Config } from "../../../config";
import { LoaderAnimation } from "@atomic/atoms/LoaderAnimation";
import "react-toastify/dist/ReactToastify.css";
import { LocalLoader, Toast } from "@atomic";
import { isAccountActivated, navigationPermission } from "@utils";
import { get } from "lodash";
import * as Sentry from "@sentry/react";
import { ToggleWebsocketProvider } from "@hooks";

const Stack = createStackNavigator();

declare global {
  interface Window {
    config: Config;
  }
}

Sentry.init({
  dsn: window.config.SENTRY_URL_DSN,
  enabled: window.config.ENV !== "development", // to prevent reporting errors in dev mode unintentionally
});

const styleLoader = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
  },
  box: {
    padding: "20px",
    color: "white",
    borderRadius: "5px",
  },
};

export function App() {
  const isIdpRedirection = get(window, "location.pathname", "").toLowerCase() === "/idp-authentication-callback";
  const [isLoading, setIsLoading] = useState(isIdpRedirection); // State to control loading


  // Handle external link redirection
  if (get(window, "location.pathname", "").toLowerCase() === "/cat") {
    window.location.replace(window.config.CAT_EXTERNAL_URL);
  }

  useEffect(() => {
    const initializeApp = async () => {
      if (isIdpRedirection) {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get("code"); // Extract the "code" parameter
        store.dispatch(LoaderActions.setLoading(true));
          const authToken: any = await store.dispatch(
            AuthThunks.loginIDP(code as string)
          );
          if (!isAccountActivated(authToken.accessToken ?? "")) {
            store.dispatch(AuthActions.setLoginStatus(true));
            store.dispatch(AuthActions.setIDPConnexionStatus(true));
          }
      }

      setIsLoading(false);
    };

    initializeApp();
  }, [isIdpRedirection]);

  if (isLoading) {
    return <div style={styleLoader.container}>
            <div style={styleLoader.box}><LocalLoader /></div>
          </div>;
  }

   // persistedStore.purge();
   store.dispatch(AuthThunks.initializeAuth0Client());

   if (!Client()) return null;

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={Client()}>
        <Provider store={store}>
          <PersistGate persistor={persistedStore}>
            <ToggleWebsocketProvider>
              <SafeAreaProvider>
                <NavigationContainer
                  ref={navigationRef}
                  linking={DeeplinkConfigs()}
                  onStateChange={(state) => navigationPermission(state, store)}
                  documentTitle={{
                    formatter: (options, route) => "Foodi",
                  }}
                >
                  <LoaderAnimation />
                  <Toast />
                  <Stack.Navigator initialRouteName={"Root"}>
                    <Stack.Screen
                      component={RootNavigator}
                      name={"Root"}
                      options={{ headerShown: false }}
                    />
                  </Stack.Navigator>
                </NavigationContainer>
              </SafeAreaProvider>
            </ToggleWebsocketProvider>
          </PersistGate>
        </Provider>
      </ApolloProvider>
      <GlobalStyles />
    </ThemeProvider>
  );
}

declare let global: {
  HermesInternal?: boolean;
};

AppRegistry.registerComponent("myprojectname", () => App);
AppRegistry.runApplication("myprojectname", {
  rootTag: document.getElementById("root"),
});
